import React, { useEffect, useState } from 'react';
import useRequest from 'hooks/useRequest';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDebouncedCallback } from 'use-debounce';

export default function Autocomplete({
  label,
  classes,
  url,
  customParams = [],
  placeholder,
  onChange,
  valueField,
  nameField,
  lastNameField,
  paramsBusinessId = '0',
  setBusinessData,
  defaultData,
  noDeleted,
  clearValue
}) {
  const { reqToken } = useRequest();

  const [focus, setFocus] = useState(false);
  const [inputValue, setInputValue] = useState(defaultData?.label || '');
  const [data, setData] = useState([]);
  // const [data, setData] = useState(Array.from({ length: 20 }));
  const [disable, setDisable] = useState(true);
  const [index, setIndex] = useState(20);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);

  const [paramsBusFound, setParamsBusFound] = useState(false);

  const mapFn = (arr) => {
    let localArr = arr.data.datos;

    if (noDeleted) {
      localArr = localArr.filter((item) => item[noDeleted] === null);
    }

    let mapped = [];

    if (lastNameField) {
      mapped = localArr.map((item) => ({
        label: item[nameField] + ' ' + item[lastNameField],
        value: item[valueField]
      }));

      return mapped;
    }

    mapped = localArr.map((item) => ({
      label: item[nameField],
      value: item[valueField]
    }));

    return mapped;
  };

  const getParamBusiness = async () => {
    const res = await reqToken(`business_admin/${paramsBusinessId}`);

    const item = { label: res.data.bus_text_name, value: res.data.bus_int_id };
    setData([item]);
    setBusinessData?.(item);

    setInputValue(item.label);

    setDisable(false);

    setParamsBusFound(true);
  };

  const getInitialData = async () => {
    setIndex(20);
    setHasMore(true);
    setLoading(true);

    const res = await reqToken.get(
      `${url}?l=20&o=0&${customParams?.join('&')}`
    );

    setData(mapFn(res));

    setLoading(false);

    if (
      paramsBusinessId !== '0' &&
      paramsBusinessId !== '' &&
      !paramsBusFound
    ) {
      return getParamBusiness();
    }

    setDisable(false);
  };

  const getMoreData = async () => {
    const res = await reqToken.get(
      `${url}?l=20&o=${index}&k=${inputValue}&${customParams?.join('&')}`
    );

    if (res.data.datos.length === 0) {
      setHasMore(false);
      return;
    }

    setData([...data, ...mapFn(res)]);
    setIndex(index + 20);
  };

  const handleSearch = useDebouncedCallback((query) => {
    if (query.length === 0) {
      getInitialData();

      return;
    }

    getFilter(query);
  }, 1000);

  const getFilter = async (query) => {
    setData([]);
    setIndex(20);
    setHasMore(false);
    setLoading(true);

    setTimeout(async () => {
      const res = await reqToken.get(
        `${url}?l=20&o=0&k=${query}&${customParams?.join('&')}`
      );

      setData(mapFn(res));

      setLoading(false);

      setHasMore(true);
    }, 1000);
  };

  useEffect(() => {
    getInitialData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (clearValue === null) return;
    setInputValue('');
  }, [clearValue]);

  return (
    <div className={`position-relative ${classes}`}>
      <div className="autocomplete-input">
        <label className="mb-1">{label}</label>
        <input
          disabled={disable}
          type="search"
          className="input-bg-dark form-control"
          placeholder={disable ? 'Cargando...' : placeholder}
          onChange={({ target }) => {
            setInputValue(target.value);
            handleSearch(target.value);
          }}
          onFocus={() => setFocus(true)}
          onBlur={() =>
            setTimeout(() => {
              setFocus(false);
            }, 200)
          }
          value={inputValue}
        />
      </div>

      {focus && !loading && (
        <div className="w-100 rounded autocomplete-box">
          <InfiniteScroll
            className="autocomplete-list"
            dataLength={data.length}
            next={getMoreData}
            // scrollThreshold={0.9}
            hasMore={hasMore}
            loader={
              data.length > 0 ? (
                <p className="text-center w-100 py-3 mb-0">Cargando...</p>
              ) : (
                <p className="text-center w-100 py-3 mb-0">Sin datos</p>
              )
            }
            height={data.length >= 10 ? 400 : 'auto'}
            endMessage={
              <p className="text-center w-100 mb-0 py-3">No hay más datos</p>
            }
          >
            {data.map((item) => (
              <div
                key={item.value}
                className="p-2 rounded autocomplete-item"
                onClick={() => {
                  onChange(item);
                  setInputValue(item.label);
                }}
              >
                {item.label}
              </div>
            ))}
          </InfiniteScroll>
        </div>
      )}

      {focus && loading && (
        <div className="w-100 rounded autocomplete-box">
          <div className="w-100 text-center py-3">Cargando...</div>
        </div>
      )}
    </div>
  );
}
