import React from "react";
import { DefaultInput } from "templates/input";

export default function ProveedoresForm({
  id,
  title,
  handleSubmit,
  handleChange,
  setData,
  loading,
  error,

  con_varchar_email,
  con_varchar_whatsapp,

  bus_text_name,
  bus_text_identification,
  bus_text_address,
  bus_text_phone,
  bus_text_domain,

  use_text_user,
  use_text_email,
  use_text_pass,
  use_text_name,
  use_text_lastname,
  use_text_phone,
}) {
  return (
    <div className="w-100 d-flex flex-column align-items-center justify-content-center rounded bg-light">
      <form
        className="row w-100 mx-0 align-items-start"
        onSubmit={handleSubmit}
        autoComplete="off"
      >
        <h3 className="text-center mb-3">
          {id !== "0" ? "Editar proveedor" : "Nuevo proveedor"} ({" "}
          {title.replace(/-/g, " ")} )
        </h3>

        <div className={`${id === "0" ? "col-12 col-lg-6" : "col-12"}`}>
          <h4>Información del proveedor</h4>
          <DefaultInput
            label="Nombre"
            type="text"
            value={bus_text_name || ""}
            inputName="bus_text_name"
            onChange={handleChange}
            placeholder="Ingresa un nombre"
            error={error?.bus_text_name}
          />
          <DefaultInput
            label="Identificación"
            type="text"
            value={bus_text_identification || ""}
            inputName="bus_text_identification"
            onChange={handleChange}
            placeholder="Ingresa una identificación"
            error={error?.bus_text_identification}
          />
          <DefaultInput
            label="Dirección"
            type="text"
            value={bus_text_address || ""}
            inputName="bus_text_address"
            onChange={handleChange}
            placeholder="Ingresa una dirección"
            error={error?.bus_text_address}
          />
          <DefaultInput
            label="Teléfono"
            type="number"
            value={bus_text_phone || ""}
            inputName="bus_text_phone"
            onChange={handleChange}
            placeholder="Ingresa un teléfono"
            error={error?.bus_text_phone}
          />
          <DefaultInput
            label="Dominio"
            value={bus_text_domain || ""}
            inputName="bus_text_domain"
            onChange={handleChange}
            placeholder="@dominio"
            error={
              error
                ? error["bus_text_domain        "] || error["bus_text_domain"]
                : null
            }
            disabled={id !== "0" ? true : false}
          />
        </div>

        {id === "0" && (
          <div className="col-12 col-lg-6">
            <h4>Información de usuario</h4>
            {/* <DefaultInput
            label="Usuario de acceso"
            type="text"
            inputName="use_text_user"
            value={use_text_user}
            onChange={handleValidationChange}
            placeholder="Ingresa un usuario"
            error={error?.use_text_user}
          /> */}

            <label htmlFor="use_text_user" className="form-label fw-bold">
              Usuario de acceso
            </label>
            <div className="input-group mb-3">
              <input
                type="text"
                name="use_text_user"
                defaultValue={use_text_user}
                onChange={handleChange}
                className={`form-control input-bg-dark ${
                  error?.use_text_user && "is-invalid"
                }`}
                placeholder="Usuario para inicio de sesión"
                aria-label="Usuario de acceso"
                aria-describedby="access_user_input"
              />
              {bus_text_domain && (
                <span className="input-group-text" id="access_user_input">
                  {bus_text_domain}
                </span>
              )}
              {error?.use_text_user && (
                <p className="mt-2 text-danger w-100 mb-0">
                  {error?.use_text_user}
                </p>
              )}
            </div>

            <DefaultInput
              label="Correo electrónico"
              type="email"
              value={use_text_email}
              inputName="use_text_email"
              onChange={handleChange}
              placeholder="Ingresa un usuario"
              error={error?.use_text_email}
            />

            <DefaultInput
              label="Contraseña"
              type="password"
              value={use_text_pass}
              inputName="use_text_pass"
              onChange={handleChange}
              placeholder="Ingresa una contraseña"
              error={error?.use_text_pass}
              required={false}
            />

            <DefaultInput
              label="Nombre"
              type="text"
              value={use_text_name}
              inputName="use_text_name"
              onChange={handleChange}
              placeholder="Ingresa un nombre"
              error={error?.use_text_name}
            />

            <DefaultInput
              label="Apellido"
              type="text"
              value={use_text_lastname}
              inputName="use_text_lastname"
              onChange={handleChange}
              placeholder="Ingresa un apellido"
              error={error?.use_text_lastname}
            />

            <DefaultInput
              label="Teléfono"
              type="number"
              value={use_text_phone}
              inputName="use_text_phone"
              onChange={handleChange}
              placeholder="Ingresa un número telefónico"
              error={error?.use_text_phone}
            />
          </div>
        )}

        <div className="col-12 d-flex justify-content-end">
          <button
            type="submit"
            className={`btn btn-${loading ? "secondary" : "primary"}`}
            disabled={loading ? true : false}
          >
            {loading ? (
              <>
                <i className="fas fa-circle-notch fa-spin me-2"></i>
                Guardando...
              </>
            ) : (
              <>
                <i className="fas fa-save me-2"></i>
                Guardar
              </>
            )}
          </button>
        </div>
      </form>
    </div>
  );
}
