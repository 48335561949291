import React from 'react';

import { DefaultInput, DefaultSelect } from 'templates/input';
import RelationEditionList from 'components/EditionGroups/RelationEditionList';

export default function UsersFormByBusiness({
  id,
  busId,
  title,
  handleSubmit,
  handleChange,
  setData,
  loading,
  error,
  parentBusiness,

  use_text_user,
  use_text_name,
  use_text_lastname,
  use_text_phone,
  use_int_admin,
  use_text_email,
  use_text_pass,
  bus_int_id
}) {
  return (
    <div className="row w-100 mx-0">
      <div
        className={`${
          id !== '0' ? 'col-7' : 'col-12'
        } px-3 px-xl-5 d-flex flex-column`}
      >
        <h3 className="text-center mb-3">
          {id !== '0' ? 'Editar' : 'Nuevo'} usuario ( {title.replace(/-/g, ' ')}{' '}
          )
        </h3>

        <DefaultSelect
          label="Seleccione un rol"
          inputName="use_int_admin"
          placeholder="Selecciona una opción"
          onChange={handleChange}
          error={error?.use_int_admin}
          defaultValue={use_int_admin}
          options={[
            { value: '0', name: 'Usuario' },
            { value: '1', name: 'Administrador' },
            { value: '2', name: 'Planificador' }
          ]}
        />

        {id === '0' && (
          <>
            <label htmlFor="use_text_user" className="form-label fw-bold">
              Usuario de acceso
            </label>
            <div className="input-group mb-3">
              <input
                type="text"
                name="use_text_user"
                defaultValue={use_text_user}
                onChange={handleChange}
                className={`form-control input-bg-dark ${
                  error?.use_text_user && 'is-invalid'
                }`}
                placeholder="Usuario para inicio de sesión"
                aria-label="Usuario de acceso"
                aria-describedby="access_user_input"
              />
              <span className="input-group-text" id="access_user_input">
                {parentBusiness?.bus_text_domain}
              </span>
              {error?.use_text_user && (
                <p className="mt-2 text-danger w-100 mb-0">
                  {error?.use_text_user}
                </p>
              )}
            </div>
          </>
        )}

        {id !== '0' && (
          <DefaultInput
            label="Usuario de acceso"
            type="text"
            value={use_text_user}
            inputName="use_text_user"
            onChange={handleChange}
            placeholder="Ingresa un usuario"
            error={error?.use_text_user}
          />
        )}

        <DefaultInput
          label="Correo electrónico"
          type="email"
          value={use_text_email}
          inputName="use_text_email"
          onChange={handleChange}
          placeholder="Ingresa un usuario"
          error={error?.use_text_email}
        />

        <DefaultInput
          label="Contraseña"
          type="password"
          value={use_text_pass}
          inputName="use_text_pass"
          onChange={handleChange}
          placeholder="Ingresa una contraseña"
          error={error?.use_text_pass}
          required={false}
        />

        <DefaultInput
          label="Nombre"
          type="text"
          value={use_text_name}
          inputName="use_text_name"
          onChange={handleChange}
          placeholder="Ingresa un nombre"
          error={error?.use_text_name}
        />

        <DefaultInput
          label="Apellido"
          type="text"
          value={use_text_lastname}
          inputName="use_text_lastname"
          onChange={handleChange}
          placeholder="Ingresa un apellido"
          error={error?.use_text_lastname}
        />

        <DefaultInput
          label="Teléfono"
          type="number"
          value={use_text_phone}
          inputName="use_text_phone"
          onChange={handleChange}
          placeholder="Ingresa un número telefónico"
          error={error?.use_text_phone}
        />

        {/* <DefaultInput
          label="Dirección"
          type="text"
          value={use_text_address}
          inputName="use_text_address"
          onChange={handleChange}
          placeholder="Ingresa una dirección"
          error={error?.use_text_address}
        /> */}

        <div className="col-12 d-flex justify-content-end">
          <button
            type="button"
            onClick={handleSubmit}
            className={`btn btn-${loading ? 'secondary' : 'primary'}`}
            disabled={loading ? true : false}
          >
            {loading ? (
              <>
                <i className="fas fa-circle-notch fa-spin me-2"></i>
                Guardando...
              </>
            ) : (
              <>
                <i className="fas fa-save me-2"></i>
                Guardar
              </>
            )}
          </button>
        </div>
      </div>

      {id !== '0' && <RelationEditionList groupFor="users" id={id} />}
    </div>
  );
}
