import { useState, useEffect } from 'react';
import useRequest from 'hooks/useRequest';

import FullWidthContainer from 'components/layout/FullWidthContainer';
import { DefaultInput } from 'templates/input';
import CargaMavisaForm from 'components/Forms/CargaMasivaForm';
import InputSelect from 'inputs/InputSelect';
import useScrollContainer from 'hooks/useScrollContainer';
import InfiniteScroll from 'react-infinite-scroll-component';
// import DeleteModal from "components/CargaMasiva/DeleteModal";

export default function CargaMasiva() {
  const { reqTokenBase } = useRequest();

  const [step, setStep] = useState(1);
  const [business, setBusiness] = useState('');
  const [currentItem, setCurrentItem] = useState({});
  const [busOptions, setBusOptions] = useState([]);
  const [obs, setObs] = useState('');
  const [obsDeny, setObsDeny] = useState('');

  const {
    loading,
    data,
    error,
    keyword,
    setKeyword,
    hasMore,
    // erreasing,
    // edit,
    getFilter,
    handleScroll,
    // handleDelete,
    // handleRestore,
    // handleHardDelete,

    getInitialData
  } = useScrollContainer({
    type: 'cargamasiva',
    url: 'administration/validacionmasiva',
    deleteUrl: 'administration/validacionmasiva',
    params: [`bus_int_id=${business}`]
  });

  // Función inicial que trae los datos apenas se ingresa al componente
  const getDataBusiness = async () => {
    try {
      const res = await reqTokenBase.get(
        `administration/infomenus/concmpendientes?l=5000&o=0`
      );

      setBusOptions(
        res.data.data.map((item) => ({
          name: item.bus_text_name,
          value: item.bus_int_id
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDataBusiness();

    //eslint-disable-next-line
  }, [step]);

  if (step === 2) {
    return (
      <CargaMavisaForm
        currentItem={currentItem}
        setStep={setStep}
        getInitialData={getInitialData}
      />
    );
  }

  return (
    <FullWidthContainer>
      <div className="w-100 d-flex flex-column">
        <h2 className="w-100 text-center">Validar cargas previas</h2>

        <ObsModal obs={obs} obsDeny={obsDeny} />

        <form
          onSubmit={getFilter}
          className="d-none d-md-flex w-100 align-items-end gap-3 mb-3"
          autoComplete="off"
        >
          <DefaultInput
            customClass="flex-grow-1"
            onChange={({ target }) => setKeyword(target.value)}
            value={keyword}
            placeholder="Ingresa palabras clave"
            mb="0"
            required={false}
          />

          <InputSelect
            className="flex-grow-1"
            label=""
            id="bus_carga_masiva"
            value={business}
            name="bus_carga_masiva"
            onChange={({ target }) => setBusiness(target.value)}
            options={busOptions}
            placeholder="Selecciona una empresa"
          />

          <button type="submit" className="btn btn-primary">
            Buscar
            <i className="fas fa-search ms-2"></i>
          </button>
        </form>

        <div className="row mx-0 w-100 border rounded bg-secondary mb-2">
          <span className="col-1 text-center text-white fw-bold border-end py-2">
            ID
          </span>
          <span className="col-5 text-center text-white fw-bold border-end py-2">
            Nombre
          </span>
          <span className="col-4 text-center text-white fw-bold border-end py-2">
            Fecha
          </span>
          <span className="col-2 text-center text-white fw-bold border-end py-2">
            Opciones
          </span>
        </div>

        {!error && !loading ? (
          <div className="w-100">
            <InfiniteScroll
              className="autocomplete-list"
              dataLength={data.length}
              next={handleScroll}
              // scrollThreshold={0.9}
              hasMore={hasMore}
              loader={
                data.length > 0 ? (
                  <p className="text-center w-100 py-3 mb-0 border">
                    Cargando...
                  </p>
                ) : (
                  <p className="text-center w-100 py-3 mb-0 border">
                    Sin datos
                  </p>
                )
              }
              height={data.length >= 10 ? 510 : 'auto'}
              endMessage={
                <p className="text-center w-100 mb-0 py-3 border">
                  No hay más datos
                </p>
              }
            >
              {data.map(({ item }) => (
                // <div className="w-100">{item.name}</div>
                <div
                  key={item.rma_int_id}
                  className="w-100 row mx-0 border-bottom-0 border py-2 hover-list-item"
                >
                  <div className="col-1 d-flex flex-column align-items-start justify-content-center border-end">
                    <p className="w-100 text-center mb-1">{item.rma_int_id}</p>
                  </div>
                  <div className="col-5 d-flex flex-column align-items-start justify-content-center border-end">
                    <p className="w-100 word-break mb-1">
                      {item.for_text_name}
                    </p>
                  </div>
                  <div className="col-4 d-flex flex-column align-items-start justify-content-center border-end">
                    <p className="w-100 word-break text-center mb-1">
                      {item.rma_date_creation_date?.split(' ')[0]}
                    </p>
                  </div>
                  <div className="col-2 d-flex align-items-center justify-content-center gap-2">
                    <button
                      onClick={() => {
                        setCurrentItem(item);
                        setStep(2);
                      }}
                      type="button"
                      className={`btn btn-${
                        item.rma_int_status === '0'
                          ? 'success'
                          : item.rma_int_status === '2'
                          ? 'danger'
                          : 'secondary'
                      } btn-sm`}
                    >
                      <i className="far fa-edit"></i>
                    </button>

                    {(item.rma_text_obs || item.rma_text_obs2) && (
                      <button
                        onClick={() => {
                          setObs(item.rma_text_obs);
                          setObsDeny(item.rma_text_obs2);
                        }}
                        type="button"
                        className="btn btn-sm btn-secondary"
                        data-bs-toggle="modal"
                        data-bs-target="#obsModal"
                      >
                        <i className="far fa-comment"></i>
                      </button>
                    )}
                  </div>
                </div>
              ))}
            </InfiniteScroll>
          </div>
        ) : (
          <div className="border w-100 text-center py-3">Cargando...</div>
        )}
      </div>
    </FullWidthContainer>
  );
}

const ObsModal = ({ obs, obsDeny }) => {
  return (
    <div
      className="modal fade"
      id="obsModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="deleteModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content bg-light">
          <div className="modal-header">
            <h5 className="modal-title" id="obsModal">
              Observaciones
            </h5>
            <button
              type="button"
              className="btn-close-modal btn text-dark"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="fas fa-times fa-lg"></i>
            </button>
          </div>
          <div className="modal-body py-5">
            <span className="form-label fw-bold">Observaciones al emitir:</span>
            <p className="1-100 text-justify m-0">{obs}</p>

            {obsDeny && (
              <>
                <div className="mt-4"></div>
                <span className="form-label fw-bold">
                  Observaciones al rechazar:
                </span>
                <p className="1-100 text-justify m-0">{obsDeny}</p>
              </>
            )}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              <i className="fas fa-times me-2"></i>
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
