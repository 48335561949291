import React, { useRef, useState } from 'react';
import { POST } from 'helpers/request';
import { useForm } from 'hooks/useForm';
import { format, add, sub, isValid } from 'date-fns';

import { DefaultTextarea } from 'templates/input';

import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const defaultValues = {
  rec_text_validar: '',
  rec_int_id: '',
  rec_int_status: ''
};

export default function AccepDenyModal({
  id,
  setRecord,
  recObs,
  auditor,
  created,
  expiration,
  categoryExpiration,
  fileName,
  setHavePendingDocs,
  rec_creado
}) {
  const [loading, setLoading] = useState(false);
  const [values, handleInputChange, reset, setValues] = useForm({
    ...defaultValues,
    rec_date_creation_date: isValid(new Date(created))
      ? new Date(created?.split(' ')[0].replace(/-/g, '/').toString())
      : new Date(),
    rec_date_expiration_date: isValid(new Date(expiration))
      ? new Date(expiration?.split(' ')[0].replace(/-/g, '/').toString())
      : new Date()
  });
  const [errorData, setError] = useState(defaultValues);
  const customId = 'accep_deny_modal' + id;
  const closeBtnRef = useRef();

  const handleAccept = async () => {
    setLoading(true);

    const res = await POST(
      {
        ...values,
        rec_date_creation_date: format(
          values.rec_date_creation_date,
          'yyyy-MM-dd'
        ),
        rec_date_expiration_date:
          categoryExpiration === '0'
            ? '3050-03-03'
            : format(values.rec_date_expiration_date, 'yyyy-MM-dd'),
        rec_int_id: id,
        rec_int_status: '0'
      },
      'documentos_revision',
      null
    );

    if (res && res.status === 201) {
      setRecord((prevState) => ({
        ...prevState,
        rec_int_status: '0',
        rec_date_creation_date: format(
          values.rec_date_creation_date,
          'yyyy-MM-dd'
        ),
        rec_date_expiration_date: format(
          values.rec_date_expiration_date,
          'yyyy-MM-dd'
        )
      }));

      setHavePendingDocs(false);

      setLoading(false);
      return closeBtnRef.current.click();
    }

    setLoading(false);
    return;
  };

  const handleAcceptNotification = async () => {
    setLoading(true);

    const res = await POST(
      {
        ...values,
        rec_date_expiration_date:
          categoryExpiration === '0'
            ? '3050-03-03'
            : values.rec_date_expiration_date,
        rec_int_id: id,
        rec_int_status: '0',
        notificar: true
      },
      'documentos_revision',
      null
    );

    if (res && res.status === 201) {
      setRecord((prevState) => ({
        ...prevState,
        rec_int_status: '0',
        rec_date_creation_date: values.rec_date_creation_date,
        rec_date_expiration_date: values.rec_date_expiration_date
      }));

      setHavePendingDocs(false);

      setLoading(false);
      return closeBtnRef.current.click();
    }

    setLoading(false);
    return;
  };

  const handleDeny = async () => {
    setLoading(true);
    setError(defaultValues);

    if (!values.rec_text_validar) {
      setError({ ...errorData, rec_text_validar: 'Debe ingresar un mensaje' });
      setLoading(false);
      return;
    }

    const res = await POST(
      {
        rec_text_validar: values.rec_text_validar,
        rec_int_id: id,
        rec_int_status: '2'
      },
      'documentos_revision',
      null,
      reset
    );

    if (res && res.status === 201) {
      setRecord((prevState) => ({ ...prevState, rec_int_status: '2' }));

      setHavePendingDocs(false);

      setLoading(false);
      return closeBtnRef.current.click();
    }

    setLoading(false);
    return;
  };

  const handleDateChange = (name, date) => {
    const formatDate = format(date, 'yyyy-MM-dd');

    if (name === 'rec_date_creation_date') {
      if (!categoryExpiration) {
        setValues({
          ...values,
          rec_date_creation_date: new Date(
            formatDate.replace(/-/g, '/').toString()
          )
        });
        return;
      }

      const o = formatDate.split('-');

      const localDate = new Date(`${o[1]}-${o[2]}-${o[0]}`);

      const endDate = format(
        add(localDate, { days: categoryExpiration }),
        'yyyy-MM-dd'
      );

      if (categoryExpiration !== '0') {
        setValues({
          ...values,
          rec_date_creation_date: new Date(
            formatDate.replace(/-/g, '/').toString()
          ),
          rec_date_expiration_date: new Date(
            endDate.replace(/-/g, '/').toString()
          )
        });
      } else {
        setValues({
          ...values,
          rec_date_creation_date: new Date(
            formatDate.replace(/-/g, '/').toString()
          )
        });
      }
    }

    if (name === 'rec_date_expiration_date') {
      if (!categoryExpiration) {
        setValues({
          ...values,
          rec_date_expiration_date: new Date(
            formatDate.replace(/-/g, '/').toString()
          )
        });
        return;
      }

      const o = formatDate.split('-');

      const localDate = new Date(`${o[1]}-${o[2]}-${o[0]}`);

      const startDate = format(
        sub(localDate, { days: categoryExpiration }),
        'y-MM-dd'
      );

      setValues({
        ...values,
        rec_date_creation_date: new Date(
          startDate.replace(/-/g, '/').toString()
        ),
        rec_date_expiration_date: new Date(
          formatDate.replace(/-/g, '/').toString()
        )
      });
    }
  };

  const { rec_text_validar, rec_date_creation_date, rec_date_expiration_date } =
    values;

  return (
    <div
      className="modal fade"
      id={customId}
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby={customId + 'label'}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content bg-light">
          <div className="modal-header">
            <h5 className="modal-title text-uppercase" id={customId + 'label'}>
              {fileName}
            </h5>
            <button
              ref={closeBtnRef}
              disabled={loading ? true : false}
              type="button"
              className="btn-close-modal btn"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="fas fa-times fa-lg"></i>
            </button>
          </div>
          <div className="modal-body">
            <p className="w-100 text-center my-4">
              Documento creado el: <span className="fw-bold">{rec_creado}</span>
            </p>

            <div className="d-flex align-items-center justify-content-center gap-3 w-100 mb-3">
              <div className="d-flex flex-column">
                <span className="mb-2">Fecha expedición</span>
                <ReactDatePicker
                  title="Fecha almacenado"
                  dateFormat="yyyy-MM-dd"
                  selected={rec_date_creation_date}
                  onChange={(date) =>
                    handleDateChange('rec_date_creation_date', date)
                  }
                />
              </div>

              <div className="d-flex flex-column">
                <span className="mb-2">Fecha expiración</span>
                {categoryExpiration !== '0' ? (
                  <ReactDatePicker
                    title="Fecha almacenado"
                    dateFormat="yyyy-MM-dd"
                    selected={rec_date_expiration_date}
                    onChange={(date) =>
                      handleDateChange('rec_date_expiration_date', date)
                    }
                  />
                ) : (
                  <span>No expira</span>
                )}
              </div>
            </div>
            <label htmlFor="" className="fw-bold">
              Observaciones
            </label>
            <p className={`mb-3 ${!recObs && 'text-danger text-center'}`}>
              {recObs ? recObs : 'No hay observaciones de el usuario'}
            </p>

            <form autoComplete="off" className="w-100 d-flex flex-column">
              <DefaultTextarea
                label="Comentario"
                value={rec_text_validar}
                inputName="rec_text_validar"
                onChange={handleInputChange}
                placeholder="Deja tu mensaje..."
                error={errorData?.rec_text_validar}
                required={false}
              />
            </form>
          </div>
          <div className="modal-footer d-flex justify-content-evenly">
            <button
              onClick={handleAccept}
              type="button"
              className="btn btn-success"
              disabled={loading ? true : false}
              // data-bs-dismiss="modal"
            >
              Aceptar documento
              <i className="fas fa-check ms-2"></i>
            </button>

            <button
              onClick={handleAcceptNotification}
              type="button"
              className="btn btn-warning"
              disabled={auditor ? (loading ? true : false) : true}
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title={auditor}
              // data-bs-dismiss="modal"
            >
              Aceptar y notificar
              <i className="fas fa-bell ms-2"></i>
            </button>

            <button
              onClick={handleDeny}
              type="button"
              className="btn btn-danger"
              disabled={loading ? true : false}
              // data-bs-dismiss="modal"
            >
              Rechazar documento
              <i className="fas fa-ban ms-2"></i>
            </button>

            <button
              type="button"
              className="btn btn-secondary"
              disabled={loading ? true : false}
              data-bs-dismiss="modal"
            >
              Cancelar
              <i className="fas fa-times ms-2"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
