/* eslint-disable react/jsx-pascal-case */
import FullWidthContainer from 'components/layout/FullWidthContainer';
import { Modal_info } from 'components/reporte-formatos-inexistentes/Modal-info';
import Autocomplete from 'components/select-search/Autocomplete';
import random_id from 'helpers/random_id';
import { GET, POST } from 'helpers/request';
import { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

export default function Report_formatos_inexistentes() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [parentBusiness, setParentBusiness] = useState(null);
  const [childBusiness, setChildBusiness] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [index, setIndex] = useState(18);

  const [infoFormato, setInfoFormato] = useState({
    tipo: '',
    loading: false,
    data: [],
    error: false,
    formato: '',
    empresa: ''
  });

  const object_builder = (obj, value_key_name) => {
    if (!obj) return;

    const entries = Object.entries(obj);

    const model = entries.map((item) => ({
      nombre: item[0],
      [value_key_name]: item[1]
    }));

    return model;
  };

  const agrupar_valores_formatos = (data) => {
    const result = [];

    for (const key in data) {
      const type = key; // Obtiene el tipo (Trabajadores o Equipos)
      const items = data[key];

      for (const itemKey in items) {
        const newItem = {
          ...items[itemKey],
          nombre: itemKey,
          tipo: type
        };
        result.push(newItem);
      }
    }

    return result;
  };

  const data_mapper = (data) => {
    const entries = Object.entries(data);

    const build_business = entries.map((business) => ({
      nombre: business[0],
      tipos: object_builder(business[1], 'formatos')
    }));

    const build_formatos = build_business.map((business) => ({
      ...business,
      tipos: business.tipos.map((tipo) => ({
        ...tipo,
        // formatos: agrupar_valores_formatos(tipo.formatos)
        formatos: agrupar_valores_formatos(tipo.formatos)
      }))
    }));

    return build_formatos;
  };

  const getData = async (business, is_child) => {
    try {
      if (!is_child) {
        setParentBusiness(null);

        setTimeout(() => {
          setParentBusiness(business);
        }, 500);
      } else {
        setChildBusiness(business);
      }

      setLoading(true);
      setData([]);
      setIndex(3);
      setHasMore(true);

      const url = !is_child
        ? `reporteformatosnoexistentes/${business.value}?offset=0&limit=3`
        : `reporteformatosnoexistentes/${parentBusiness.value}/${business.value}`;

      const res = await GET(url);

      if (res.status === 404) {
        setHasMore(false);
        setLoading(false);
        setData([]);
        return;
      }

      const data = data_mapper(res);

      setData(data);

      setLoading(false);
    } catch (err) {
      setHasMore(false);
      setLoading(false);
      setData([]);
    }
  };

  const handleScroll = async () => {
    if (childBusiness) {
      setHasMore(false);
      return;
    }

    try {
      setIndex(index + 3);

      const res = await GET(
        `reporteformatosnoexistentes/${parentBusiness.value}?offset=${index}&limit=3`
      );

      if (res.status === 404) {
        setHasMore(false);
        return;
      }

      const extra_data = data_mapper(res);

      if (extra_data.length === 0) {
        setHasMore(false);
        return;
      }

      setData([...data, ...extra_data]);
    } catch (error) {
      setHasMore(false);
    }
  };

  const get_info = async (data) => {
    try {
      setInfoFormato({
        ...infoFormato,
        loading: true
      });

      const res = await POST(data, 'reporteformatosnoexistentes');

      const values = Object.values(res);

      const filtered = values
        .filter((el) => typeof el !== 'string')
        .sort((a, b) => a.isvalid - b.isvalid);

      const formato = res.formato;
      const empresa = res.proveedor;

      setInfoFormato({
        ...infoFormato,
        tipo: data.type,
        loading: false,
        data: filtered,
        formato,
        empresa
      });
    } catch (err) {
      setInfoFormato({
        tipo: '',
        loading: false,
        data: [],
        error: false,
        formato: '',
        empresa: ''
      });
    }
  };

  const Valores = ({ formato }) => {
    const { for_int_id, typ_int_id, bus_int_id } = formato;
    const trabajador = formato.tipo === 'Trabajadores';
    const valores = `${formato.cumpliendo} / ${formato.asignados}`;

    const onClick = () =>
      get_info({
        for_int_id,
        typ_int_id,
        bus_int_id,
        type: trabajador ? 'trabajadores' : 'equipos'
      });

    if (trabajador) {
      return (
        <>
          <td
            onClick={onClick}
            data-bs-toggle="modal"
            data-bs-target="#reporte-formatos-inexistentes-modal"
            className="hover-list-item cursor-pointer"
          >
            {valores}
          </td>
          <td>---</td>
        </>
      );
    }

    return (
      <>
        <td>---</td>
        <td
          onClick={onClick}
          data-bs-toggle="modal"
          data-bs-target="#reporte-formatos-inexistentes-modal"
          className="hover-list-item cursor-pointer"
        >
          {valores}
        </td>
      </>
    );
  };

  return (
    <FullWidthContainer>
      <Modal_info infoFormato={infoFormato} />

      <h2 className="text-center mb-5">Reporte Supervisor</h2>

      <div className="d-flex align-items-end gap-3 mb-3">
        <Autocomplete
          label="Selecciona una empresa"
          classes="flex-grow-1"
          url="business_admin"
          //son los nombres de los campos de el esquema, de id y de nombre
          valueField="bus_int_id"
          nameField="bus_text_name"
          placeholder="Escoja una opción"
          onChange={getData}
        />

        {parentBusiness && (
          <Autocomplete
            label="Selecciona un proveedor"
            classes="flex-grow-1"
            url="business_admin"
            customParams={[`c=${parentBusiness.value}`]}
            //son los nombres de los campos de el esquema, de id y de nombre
            valueField="bus_int_id"
            nameField="bus_text_name"
            placeholder="Escoja una opción"
            onChange={(item) => {
              getData(item, true);
            }}
          />
        )}
      </div>

      {loading && (
        <div className="text-center boder py-3 mt-3">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}

      {!loading && data && data.length === 0 && (
        <div className="fw-bold text-uppercase text-center boder py-3 mt-3">
          No hay datos
        </div>
      )}

      {!loading && data && data.length > 0 && (
        <InfiniteScroll
          className="w-100"
          dataLength={data.length}
          next={handleScroll}
          // scrollThreshold={0.9}
          hasMore={hasMore}
          loader={
            <p className="text-center w-100 py-3 mb-0 border">Cargando...</p>
          }
          endMessage={
            <p className="text-center w-100 mb-0 py-3 border">
              No hay más datos
            </p>
          }
          height={600}
        >
          {data.map((business) => {
            const tipos = business.tipos;

            return (
              <div className="mb-5" key={random_id()}>
                <h5 className="mb-3 fw-bold border-bottom pb-2">
                  {business.nombre}
                </h5>
                <table className="table table-striped table-bordered">
                  <thead>
                    <tr className="bg-secondary text-white">
                      <th style={{ width: '30%' }} scope="col">
                        Tipo documental
                      </th>
                      <th style={{ width: '30%' }} scope="col">
                        Formato
                      </th>
                      <th style={{ width: '20%' }} scope="col">
                        Trabajadores
                      </th>
                      <th style={{ width: '20%' }} scope="col">
                        Equipo
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {tipos.map((tipo, tipo_idx) => {
                      const formatos = tipo.formatos;
                      const formato_cero = formatos[0];
                      const otros_formatos = formatos.filter((_, i) => i !== 0);

                      return (
                        <>
                          <tr>
                            <th rowSpan={formatos.length} scope="rowgroup">
                              {tipo.nombre}
                            </th>

                            <th scope="row">{formato_cero.nombre}</th>
                            <Valores formato={formato_cero} />
                          </tr>

                          {otros_formatos.map((formato) => (
                            <tr key={random_id()}>
                              <th scope="row">{formato.nombre}</th>
                              <Valores formato={formato} />
                            </tr>
                          ))}
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            );
          })}
        </InfiniteScroll>
      )}
    </FullWidthContainer>
  );
}
