import useAuth from 'auth/useAuth';
import axios from 'axios';
import RelationList from 'components/RequisitosRelationList/RelationEditionList';
import useRequest from 'hooks/useRequest';
import React, { useEffect, useState } from 'react';
import QRCode from 'react-qr-code';
import { DefaultInput } from 'templates/input';

export default function TrabajadoresForm({
  id,
  title,
  handleSubmit,
  handleChange,
  setData,
  loading,
  error,
  busId,

  tra_varchar_nombres,
  tra_varchar_apellidos,
  tra_varchar_dni,
  tra_date_carnet_date,
  qrgarita,
  qrcarnet
}) {
  const auth = useAuth();

  const { reqTokenBase, reqDownload, donwloadFile, reqTokenBaseFormData } =
    useRequest();

  const [loadingCarnet, setLoadingCarnet] = useState(false);

  const [fileError, setFileError] = useState('');

  const [pictures, setPictures] = useState([]);

  const [currentPicture, setCurrentPicture] = useState(`${qrcarnet}/foto`);

  const checkCarnet = async () => {
    const res = await reqTokenBase.get(`administration/trabajadores/${id}`);
    setData((prevData) => ({
      ...prevData,
      tra_date_carnet_date: res.data.tra_date_carnet_date
    }));

    if (loadingCarnet && res.data.tra_date_carnet_date) setLoadingCarnet(false);
  };

  const disableCarnet = async () => {
    setLoadingCarnet(true);
    await axios.delete(`${qrcarnet}`, {
      headers: {
        Authorization: `Bearer ${auth.user.access_token}`
      }
    });
  };

  const handleCarnetPDF = async (type) => {
    const file = await reqDownload.get(
      `empresa/trabajadores/${id}/carnetpdf${type}`
    );
    donwloadFile(
      file.data,
      `Carnet-${tra_varchar_nombres}-${tra_varchar_apellidos}.pdf`
    );
  };

  const saveCarnetFile = async ({ target }) => {
    setFileError('');

    if (target.files.length === 0) return;

    const limitePesoMB = 1; // Límite en MB
    const limitePesoBytes = limitePesoMB * 1024 * 1024; // Convertir a bytes

    if (target.files[0].size > limitePesoBytes) {
      return setFileError('La imagen debe tener como máximo 1 MB de peso'); // El archivo excede el límite de peso
    }

    const formData = new FormData();

    formData.append('qr', qrcarnet);
    formData.append('file', target.files[0]);

    try {
      await reqTokenBaseFormData.post('ct', formData);
      refreshPicture();
    } catch (error) {
      setFileError(error.response.data.messages.error);
    }
  };

  const getPictues = async () => {
    const res = await axios.get(`${qrcarnet}/galeria`);
    setPictures(res.data.fotos);
  };

  const refreshPicture = async () => {
    const res = await reqTokenBase.get(`administration/trabajadores/${id}`);
    setCurrentPicture(`${res.data.qrcarnet}/foto`);
  };

  useEffect(() => {
    if (id === '0') return;

    if (id !== '0') getPictues();

    const interval = setInterval(() => {
      checkCarnet();
    }, 10000);
    return () => clearInterval(interval);

    // eslint-disable-next-line
  }, []);

  return (
    <div className="w-100 row mx-0 g-3 align-items-start">
      <h3 className="text-center mb-3 col-12">
        {id !== '0' ? 'Editar trabajador' : 'Nuevo trabajador'} ({' '}
        {title.replace(/-/g, ' ')} )
      </h3>

      <div
        className={`row mx-0 ${
          id !== '0' ? 'col-12 col-md-8' : 'col-12'
        } align-items-center`}
      >
        <DefaultInput
          customClass="col-12"
          label="Nombres"
          placeholder="e.j. Jhon"
          value={tra_varchar_nombres}
          inputName="tra_varchar_nombres"
          onChange={handleChange}
          error={error.tra_varchar_nombres}
        />

        <DefaultInput
          customClass="col-12"
          label="Apellidos"
          placeholder="e.j. Doe"
          value={tra_varchar_apellidos}
          inputName="tra_varchar_apellidos"
          onChange={handleChange}
          error={error.tra_varchar_apellidos}
        />

        <DefaultInput
          customClass="col-12"
          label="N° de cédula / Pasaporte"
          placeholder="e.j. 01234567890"
          value={tra_varchar_dni}
          inputName="tra_varchar_dni"
          onChange={handleChange}
          error={error.tra_varchar_dni}
        />

        {id !== '0' && (
          <DefaultInput
            label="Foto de carnet"
            type="file"
            onChange={saveCarnetFile}
            error={fileError}
            required={false}
          />
        )}

        <div className="col-12 d-flex justify-content-end flex-wrap gap-3">
          {id !== '0' && qrcarnet && (
            <div className="d-flex align-items-center justify-content-center gap-3 me-auto flex-wrap">
              <button
                type="button"
                onClick={() => handleCarnetPDF('a7')}
                className="btn btn-secondary"
              >
                Carnet PDF A7 <i className="fas fa-file-pdf"></i>
              </button>
              <button
                type="button"
                onClick={() => handleCarnetPDF('a4')}
                className="btn btn-secondary"
              >
                Carnet PDF A4 <i className="fas fa-file-pdf"></i>
              </button>
            </div>
          )}

          <button
            type="button"
            onClick={handleSubmit}
            className={`btn btn-${loading ? 'secondary' : 'primary'}`}
            disabled={loading ? true : false}
          >
            {loading ? (
              <>
                <i className="fas fa-circle-notch fa-spin me-2"></i>
                Guardando...
              </>
            ) : (
              <>
                <i className="fas fa-save me-2"></i>
                Guardar
              </>
            )}
          </button>
        </div>
      </div>

      {id !== '0' && (
        <div className="col-12 col-md-4">
          <div className="d-flex flex-column justify-content-center align-items-center">
            {!tra_date_carnet_date && id !== '0' && (
              <>
                <h5 className="text-uppercase text-center mb-4">
                  QR activación carnet
                </h5>
                <QRCode value={qrcarnet || tra_varchar_nombres} size={180} />
              </>
            )}
            <h5 className="text-uppercase text-center my-4">Foto actual</h5>
            <img
              style={{
                width: '180px',
                height: '180px',
                objectFit: 'cover'
              }}
              src={currentPicture}
              alt={`${tra_varchar_nombres} ${tra_varchar_apellidos} - Foto carnet`}
              // onError={({ target }) => (target.className = 'd-none')}
            />

            {tra_date_carnet_date && (
              <button
                type="button"
                onClick={disableCarnet}
                className="btn btn-warning my-5"
                disabled={loadingCarnet ? true : false}
              >
                {tra_date_carnet_date ? 'Desvincular carnet' : 'Desvinculado'}
              </button>
            )}
          </div>
        </div>
      )}

      {id !== '0' && <div className="col-12 border-bottom my-4 pt-3"></div>}

      {id !== '0' && (
        <RelationList columns="8" id={id} busId={busId} type="trabajador" />
      )}

      {id !== '0' && (
        <div className="col-12 col-md-4">
          <div className="d-flex flex-column justify-content-center align-items-center">
            <h5 className="text-uppercase text-center my-4">
              QR acceso garita
            </h5>
            <QRCode value={qrgarita || tra_varchar_nombres} size={180} />
          </div>
        </div>
      )}

      {id !== '0' && pictures.length ? (
        <div className="col-12 row mx-0 row-cols-3 g-3">
          <h3 className="col-12">Galería</h3>

          {pictures.map((item) => (
            <div
              key={item.fc_int_id}
              className="col d-flex align-items-center justify-content-center"
            >
              <img
                width="180"
                height="180"
                src={`${qrcarnet}/galeriafoto/${item.fc_text_name2}`}
                alt={item.fc_text_name2}
              />
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
}
