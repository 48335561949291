/*

  ----- Explicación -----

  - Para que sea más fácil listar todas las rutas esta este archivo, que será listado por la navegación lateral
  y solo requerirá que se modifique aquí las rutas haciendolo más fácil de usar.

  - Cada grupo o "[]" dentro de RouterData creada un grupo separado y marcado dentro de la navegación lateral.
  - Dentro de cada grupo, para crear un link o ruta, basta con crear un objeto con su ruta en la key "link" y su
  nombre en "name".
  -Para crear una lista de rutas, se creará un objeto con el key "link", solo el nombre de la lista, y en la pro-
  piedad "childrens" se listara dentro de un "[]" las rutas de la misma forma que antes.

*/

const RouterData = [
  //grupo 2
  [
    {
      name: 'Situación documental',
      childrens: [
        {
          link: '/situacionDocumental/0',
          name: 'Requisitos empresa'
        },
        {
          link: '/situdoccla',
          name: 'Clasificación'
        },
        {
          link: '/situdocwoh',
          name: 'Trabajador / Equipo'
        }
      ]
    }
    // {
    //   link: "/situacionDocumental/0",
    //   name: "Situación documental ( Empresa )",
    // },
    // {
    //   link: "/situdocclasi",
    //   name: "Situación documental ( Clasificación )",
    // },
  ],
  //grupo 5
  [
    // {
    //   link: "/crud/types",
    //   name: "Tipos",
    // },
    // {
    //   link: "/crud/formats",
    //   name: "Formatos",
    // },
    {
      link: '/crud/access',
      name: 'Accesos'
    },
    {
      link: '/planes',
      name: 'Planes'
    },
    {
      link: '/crud/business_admin',
      name: 'Empresas'
    },
    {
      link: '/crud/users',
      name: 'Usuarios'
    },
    {
      link: '/crud/blacklist',
      name: 'Vetados'
    }
  ],
  [
    {
      name: 'Reportes',
      childrens: [
        {
          name: 'General',
          link: '/reportgeneral'
        },
        {
          name: 'Por validar',
          link: '/reportvalidation'
        },
        {
          name: 'Por auditar',
          link: '/reportaudit'
        },
        {
          name: 'Doc Válidos',
          link: '/reportvalids'
        },
        {
          name: 'Doc Inexistentes',
          link: '/reportinexistentes'
        },
        {
          name: 'Situación documental',
          link: '/reportsituDoc'
        },
        {
          name: 'Situación documental General',
          link: '/reportformatos'
        },
        {
          name: 'Prov. Tipos',
          link: '/reporttype'
        },
        {
          name: 'Cumplimiento',
          link: '/reportprov'
        },
        {
          name: 'Por expirar',
          link: '/reportexpirar'
        },
        {
          name: 'Desempeño',
          link: '/reportDesempeno'
        },
        {
          name: 'Por clasificación',
          link: '/reportclasificacion'
        },
        {
          name: 'Sin asignar',
          link: '/reportsinasignar'
        },
        {
          name: 'Por cliks',
          link: '/reportclicks'
        },
        {
          name: 'Docs de empresa por trabajador, equipo o empresa',
          link: '/reportedocsempresa'
        },
        {
          name: 'Documentos historicos de trabajador por empresa',
          link: '/reporte21'
        },
        {
          name: 'DocsEmpresa',
          link: '/reporte22'
        },
        {
          name: 'Revisión de documentos por empresa y mes',
          link: '/reporte23'
        },
        {
          name: 'Supervisor',
          link: '/reporte_formatos_no_existentes'
        }
      ]
    }
  ],
  [
    {
      link: '/cargardatos',
      name: 'Cargar datos'
    }
  ],
  [
    {
      link: '/marketing/newsletter',
      name: 'Comunicación'
    }
  ],
  [
    {
      link: '/cargas',
      name: 'Validación masiva'
    }
  ]
  // [
  //   {
  //     link: "/test",
  //     name: "Test",
  //   },
  // ],
];

export default RouterData;
