import random_id from 'helpers/random_id';

export const Modal_info = ({ infoFormato }) => {
  const { tipo, loading, data, error, formato, empresa } = infoFormato;

  const colors_table = {
    0: 'table-bg-red',
    1: 'table-bg-green',
    2: 'table-bg-blue'
  };

  return (
    <div
      className="modal fade"
      id="reporte-formatos-inexistentes-modal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="reporte-formatos-inexistentes-modal-label"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-xl modal-fullscreen-lg-down modal-dialog-scrollable">
        <div className="modal-content bg-light">
          <div className="modal-header">
            <h5
              className="modal-title text-uppercase"
              id="reporte-formatos-inexistentes-modal-label"
            >
              Información {tipo} - {empresa}
            </h5>
            <button
              type="button"
              className="btn-close-modal btn"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="fas fa-times fa-lg"></i>
            </button>
          </div>
          <div className="modal-body d-flex flex-column">
            {loading && !error && <p>Cargando...</p>}

            {error && (
              <p>
                No se pudo obtener la información, un error ocurrió, contacta
                con soporte
              </p>
            )}

            {!loading && !error && (
              <>
                <h5 className="mb-3">Formato: {formato}</h5>
                <div className="d-flex justify-content-center gap-4 mb-2">
                  <span className="d-flex gap-2 align-items-center justify-content-center">
                    <div
                      style={{ width: '10px', height: '10px' }}
                      className="bg-danger rounded"
                    />
                    No cumple
                  </span>
                  <span className="d-flex gap-2 align-items-center justify-content-center">
                    <div
                      style={{ width: '10px', height: '10px' }}
                      className="bg-success rounded"
                    />
                    Cumple
                  </span>
                  <span className="d-flex gap-2 align-items-center justify-content-center">
                    <div
                      style={{ width: '10px', height: '10px' }}
                      className="bg-primary rounded"
                    />
                    Sin asignar
                  </span>
                </div>
                <table className="table table-striped table-bordered">
                  <thead>
                    <tr className="bg-secondary text-white">
                      <th scope="col">Nombre / Placa / Serie</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((item) => (
                      <tr
                        key={random_id()}
                        className={colors_table[item.isvalid]}
                      >
                        <td>{item.nombres}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            )}
          </div>
          <div className="modal-footer d-flex justify-content-between">
            <button
              type="button"
              className="btn btn-danger"
              data-bs-dismiss="modal"
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
